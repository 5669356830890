<template>
  <a-modal
      v-drag-modal
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :title="title"
      :width="action=='new'?500:1100"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <div style="display: flex">
      <div style="flex: 2">
        <div style="padding-bottom:10px;" v-show="action=='edit'">基本信息：</div>
        <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
          <a-form-model-item
              label="因子编码"
              prop="factorNo" required
              v-bind="formItemLayout"
          >
            <a-select :options="letterList" v-model="form.factorNo" v-decorator="['factorNo']">
            </a-select>
          </a-form-model-item>
          <a-form-model-item
              label="因子名称"
              prop="factorName"
              v-bind="formItemLayout">
            <a-input
                v-model="form.factorName"
                allow-clear
                placeholder="输入因子名称"
            />
          </a-form-model-item>
          <a-form-model-item label="备注说明" v-bind="formItemLayout">
            <a-textarea v-model="form.remark" :rows="4" allClear/>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div style="flex: 3" v-show="action=='edit'">
        <div style="padding-bottom:10px;">结果区间：<span style="font-size: 0.9em;color:#ff9900">(请自行保证分数区间的连续性)</span>
          <a-button type="primary" size="small" icon="plus" style="float: right" @click="handleAddScore"
                    :disabled="action=='new'">增加
          </a-button>
        </div>
        <a-table :columns="columns" :data-source="scoreList" bordered size="small" :pagination="false"
                 :scroll="{y:300}"
        >
          <template slot="score" slot-scope="text, record">
            {{ record.fromScore || 0 }}&lt;=[分值]&lt;{{ record.toScore || 'MAX' }}
          </template>
          <template slot="operation" slot-scope="text, record">
            <a-button type="primary" size="small" style="margin-right:15px;" icon="edit"
                      @click="handleEditScore(record)">
            </a-button>
            <a-button type="danger" size="small" @click="deleteScore(record)" icon="delete"></a-button>
          </template>
        </a-table>
      </div>
    </div>
    <div slot="footer">
      <div style="display: flex;justify-content: center">
        <a-button type="primary" @click="handleOk" :loading="confirmLoading"
                  style="margin-right: 40px;">保存
        </a-button>
        <a-button type="cancel" @click="visible=false">取消</a-button>
      </div>
    </div>
    <add-score-scope ref="addScoreScope" @success="editSuccess"/>
  </a-modal>
</template>
<script>
import AddScoreScope from './AddScoreScope';

export default {
  name: "ModifyResultFactorModal",
  components: {AddScoreScope},
  data() {
    return {
      action: 'edit',
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 17},
      },
      title: "",
      visible: false,
      confirmLoading: false,
      record: {},
      form: {},
      letterList: [],
      scoreList: [],
      rules: {
        factorName: [
          {
            required: true,
            message: "请输入因子名称",
            trigger: "blur",
          },
        ],
      },
      editMode: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          title: '分数',
          scopedSlots: {customRender: 'score'},
          width: 150,
        },
        {
          title: '说明',
          dataIndex: 'desc',
          ellipsis: true
        },
        {
          title: '操作',
          align: 'center',
          width: 120,
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
        },
      ];
    }
  },
  created() {
    let list = [];
    for (let i = 65; i <= 90; i++) {
      list.push({
            value: String.fromCharCode(i),
            label: String.fromCharCode(i)
          }
      );
    }
    this.letterList = list;
  },
  methods: {
    async showModal(record, action) {
      this.visible = true;
      this.action = action || 'edit';
      this.record = record || {}
      if (this.action == 'edit') {
        this.title = "结果因子ID：" + record.id;
        this.$get("evaluation/getFactorDetail", {factorId: record.id}).then(res => {
          let data = res.data
          this.form = {
            ...data,
          };
          this.scoreList = JSON.parse(data.scoreJson || "[]")
        })
      } else {
        this.form = {
          ...(record || {}),
          factorNo: this.letterList[0].value
        };
        this.scoreList = [];
        this.title = "新增结果因子";
      }
    },
    deleteScore(score) {
      let that = this;
      this.$confirm({
        title: "删除确认",
        content: "您确定要删除当前结果区间吗？！",
        centered: true,
        onOk() {
          that.$postJson("evaluation/deleteScore", {factorId: score.factorId, index: score.index}).then((res) => {
            that.$message.success("删除成功");
            that.scoreList = res.data || []
          });
        },
      });
    },
    handleAddScore() {
      this.$refs.addScoreScope.showModal({factorId: this.form.id}, 'new')
    },
    handleEditScore(record) {
      this.$refs.addScoreScope.showModal(record, 'edit');
    },
    editSuccess(list) {
      this.scoreList = list || []
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid, values) => {
        if (valid) {
          this.confirmLoading = true;

          this.$postJson("evaluation/saveFactor", this.form).then((res) => {
            this.confirmLoading = false;
            if (this.action == 'new') {
              this.form.id = res.data;
              this.action = 'edit'
            } else {
              this.visible = false;
            }
            this.$message.success("保存成功");
            this.$emit("success");
          }).catch(()=>{
            this.confirmLoading = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    }
  }
}
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}

.tip1 {
  color: #FF9900;
  margin: 0px 80px 20px;
}

</style>
